//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'

//Packages
import Helmet from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

//Components
import Layout from '../components/layout'
// import PostCta from '../components/marketing/postCta'
// import PostToc from '../components/marketing/postToc'
// import PromoSurveyWeb3 from '../components/marketing/promoSurveyWeb3'
// import PromoCampaignEightUseCases from '../components/marketing/promoCampaignEightUseCases'
// import PromoCampaignContentAuth from '../components/marketing/promoCampaignContentAuth'
// import PromoCampaignClimate from '../components/marketing/promoCampaignClimate'

//Icons
import { FaArrowRight } from 'react-icons/fa'

// const components = () => {
//   return {
//     wrapper: ({ children, ...props }) => {
//       const tagArray = React.Children.toArray(children).map((child) => {
//         console.log('**child')
//         console.log(child)
//         return child && child.props && child.props.originalType
//           ? child.props.originalType
//           : null
//       })
//       // const indexedHeader = tagArray.reduce(
//       //   (a, e, i) => (e === 'h1' ? a.concat(i) : a),
//       //   []
//       // )
//       const indexedHeader = tagArray.reduce(
//         (a, e, i) => (e ? a.concat(i) : a),
//         []
//       )
//       let filteredChildren
//       if (!props.end) {
//         filteredChildren = React.Children.toArray(children).filter(
//           (item, i) => i >= indexedHeader[props.start || 0]
//         )
//       } else {
//         filteredChildren = React.Children.toArray(children).filter(
//           (item, i) =>
//             i >= indexedHeader[props.start || 0] &&
//             i < indexedHeader[props.end || 1]
//         )
//       }

//       return <>{filteredChildren}</>
//     },
//   }
// }
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  console.log('articleTemplate - data')
  console.log(data)
  return (
    <Layout>
      {' '}
      {/* <Helmet title={`Sidepath - ${frontmatter.title}`}>
        <meta property="description" content={frontmatter.metatitle} />
        <meta property="keywords" content={frontmatter.metakeywords} />
        <meta property="og:site_name" content={'Sidepath'} />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="author" content={'Sidepath'} />
        <meta property="og:type" content={'article'} />

        <meta
          property="og:image"
          content={
            frontmatter.metaimage
              ? `http://${process.env.HOSTNAME}${frontmatter.metaimage}`
              : `http://${process.env.HOSTNAME}${frontmatter.featuredImage.childImageSharp.fluid.src}`
          }
        />
        <meta
          property="og:image:secure_url"
          content={
            frontmatter.metaimage
              ? `https://${process.env.HOSTNAME}${frontmatter.metaimage}`
              : `https://${process.env.HOSTNAME}${frontmatter.featuredImage.childImageSharp.fluid.src}`
          }
        />
        <meta property="twitter:card" content={`summary_large_image`} />
        <meta property="twitter:site" content={`@piconexthq`} />
        <meta name="twitter:title" content={frontmatter.title}></meta>
        <meta name="twitter:description" content={frontmatter.metadesc}></meta>
        <meta
          property="twitter:image"
          content={
            frontmatter.metaimage
              ? `https://${process.env.HOSTNAME}${frontmatter.metaimage}`
              : `https://${process.env.HOSTNAME}${frontmatter.featuredImage.childImageSharp.fluid.src}`
          }
        />
        <script type="application/ld+json">
          {`
    [{
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${frontmatter.title}",
      "image": [
        "${
          frontmatter.metaimage
            ? frontmatter.metaimage
            : `http${process.env.STAGE === 'dev' ? '' : 's'}://${
                process.env.HOSTNAME
              }${frontmatter.featuredImage.childImageSharp.fluid.src}`
        }"
       ],
      "datePublished": "${frontmatter.date}T08:00:00+08:00",
      "dateModified": "${frontmatter.updated}T08:00:00+08:00",
      "author": [{
          "@type": "Organization",
          "name": "Sidepath",
          "url": "https://sidepath.xyz"
        }]
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "http${process.env.STAGE === 'dev' ? '' : 's'}://${
            process.env.HOSTNAME
          }"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Resources",
        "item": "http${process.env.STAGE === 'dev' ? '' : 's'}://${
            process.env.HOSTNAME
          }/resources"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "${frontmatter.title}"
      }]
    }
  ]
    `}
        </script>
      </Helmet> */}
      {/* <div class="blog-frontmatter" style={{ height: 400 }}>
        <div
          class="gradient-bg-md-blog"
          style={{ position: 'absolute', zIndex: 1 }}
        />
        <Img
          style={{
            height: 400,
            width: '100%',
            position: 'absolute',
            zIndex: 0,
          }}
          fluid={frontmatter.featuredImage.childImageSharp.fluid}
        />
        <div
          class="container d-flex align-items-center justify-content-center"
          style={{ height: 400, position: 'relative', zIndex: 5 }}
        >
          <div class="row d-flex align-items-center justify-content-center w-100">
            <div class="col-lg-8 text-center d-flex flex-column align-items-center justify-content-center">
              {frontmatter.path.includes('customers') ? (
                <>
                  <div class="small font-weight-bold text-uppercase white-1">
                    Customer success
                  </div>
                  <hr size={1} class="bg-gray-2 mt-3 mb-3" />
                </>
              ) : null}
              <h1 class="white-1">{frontmatter.title}</h1>
              {frontmatter.subtitle ? (
                <h2 class="mt-2 white-1 font-weight-light">
                  {frontmatter.subtitle}
                </h2>
              ) : null}
            </div>
          </div>
        </div>
      </div> */}
      <div
        class={`container container-blog container-blog-${data?.articles?.id}`}
      >
        <div class="row">
          <div class={'col-md-12'}>
            <h1 class="h2 mt-4 mb-3">{data?.articles?.data?.title}</h1>
            <hr size={1} />
          </div>
        </div>
        <div class="row">
          <div class={'col-md-8'}>
            {/* <div className="blog-subhead mt-3">
              <Link to="/resources/" className="font-weight-bold">
                {frontmatter.path.includes('customers')
                  ? 'Customer Success'
                  : 'Resources'}
              </Link>{' '}
              / {frontmatter.date}{' '}
              {frontmatter.date &&
              frontmatter.updated &&
              frontmatter.updated !== frontmatter.date &&
              !frontmatter.updated.includes('Invalid') ? (
                <span class="pl-2 dkblue-1">Updated {frontmatter.updated}</span>
              ) : null}
            </div> */}
            <div className="blog-post-container">
              <div className="blog-post">
                <MDXRenderer>
                  {data?.articles?.data?.markdown?.childMdx?.body}
                </MDXRenderer>
              </div>
            </div>
          </div>
          <div class={'col-md-4 px-0 col-callout-cta pb-3'}>
            <Link
              className="callout-cta-container text-center text-md-left"
              to="/landing/passadicos-paiva"
            >
              <div class="bg-gray-6 rounded-corners border-1-gray-4 p-3 w-100 d-flex flex-column align-items-center justify-content-center">
                <div class="callout-cta-title font-weight-bold">
                  Get your travel guide:
                </div>
                <div class="callout-cta-title-sub font-weight-normal">
                  Passadiços do Paiva
                </div>
                <div class="guide-cover-image-cta border-1-gray-5 d-flex align-items-center justify-content-center">
                  <StaticImage
                    src="../../images/guide/passadicos/01-cover.png"
                    alt="Passadiços do Paiva Travel Guide"
                    loading="lazy"
                    placeholder="blurred"
                    // layout="fullWidth"
                    width={300}
                    // className="mx-3"
                    // height={400}
                    // style={{ position: `absolute` }}
                  />
                </div>
                <div>
                  <div class="callout-cta-text  py-1">What's inside:</div>
                  <ul class="callout-cta-benefits">
                    <li>
                      Curated travel suggestions about Passadiços do Paiva and
                      516 Arouca Bridge
                    </li>
                    <li>In-depth descriptions and maps</li>
                    <li>Overview of different routes and journey options</li>
                    <li>Thoughtful suggestions on area amenities</li>
                    <li>Practical information for your trip</li>
                    <li>Tips for traveling with kids</li>
                    <li>And much more...</li>
                  </ul>
                </div>
                <button className="btn btn-primary btn-block btn-lg">
                  See more <FaArrowRight class="btn-arrow" />
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    articles: airtable(id: { eq: $id }, table: { eq: "article" }) {
      id
      data {
        title
        desc
        mapcontext
        url
        publish
        publishdate(formatString: "MMMM D, YYYY")
        tags
        markdown {
          childMdx {
            body
            headings {
              value
              depth
            }
          }
        }
      }
    }
  }
`
